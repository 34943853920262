html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
video::cue {
  font-size: 2rem;
  font-family: 'Inter';
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

html,
body,
#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
